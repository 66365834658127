* {
  margin: 0;
  box-sizing: border-box;
  padding: 0;
}

body {
  background: #f7f7f7;
}

/* navigation link removed default values */
a {
  text-decoration: none;
  color: unset;
}

.profile_container {
  display: flex;
  justify-items: end;
  align-items: flex-end;
  position: relative;
  align-self: flex-end;
}

.profile {
  align-items: flex-end;

  z-index: 1000;
  justify-self: flex-end;
}

.dropdown-menu {
  background-color: white;
  display: flex;
  color: #000000;
  border-radius: 10px;
  padding: 5px;
}
.dashboard {
  width: 100vw;
}

.photo {
  height: 250px;
  width: 300px;
}

/* .faeye {
	margin-left: 40%;
} */
.centerTableData {
  text-align: center;
}

/* navigation link removed default values */

.checkbox {
  /* width: 100%;
	height: 100%; */
  /* padding-top: 10px; */
  width: 20px;
  height: 30px;
  /* size: 10px; */
}

/*============================ navbar ===========================*/
ul {
  list-style: none;
}

.nav {
  height: 70px;
  display: flex;
  justify-content: space-between;

  padding: 20px 30px;
  background-color: #8713d4;
  color: white;
  width: 100%;
}

.date-text {
  color: whitesmoke;
  margin-right: 15px;
  font-size: 14px;
}

.menu {
  display: flex;
  gap: 20px;
  align-items: center;
  font-weight: 400;
}

.openMenuPopup {
  width: 70px;
  position: fixed;
  z-index: 100;
  background-color: white;
  color: black;
  font-size: 13px;
}

.navlogimg {
  width: 20%;
  transform: rotate(180deg);
}

.log {
  margin-top: 3%;
  margin-left: 15%;
  padding-top: 5%;
  display: flex;
  justify-content: space-around;
  color: white;
  background-color: #410d63;
  width: 70%;
  height: 30px;
  border: none;
  border-radius: 3px;
  font-size: 16px;
}

.log:hover {
  cursor: pointer;
  background-color: #ad42f5;
}

.log2 {
  margin-top: 3%;
  margin-left: 15%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  color: white;
  background-color: #410d63;
  width: 200px;
  height: 30px;
  border: none;
  border-radius: 3px;
  font-size: 16px;
}

.log2:hover {
  cursor: pointer;
  background-color: #ad42f5;
}

/*============================= end Navbar ========================*/
.dashboard {
  display: flex;
  position: relative;
}

/*============================== Sidebar ===========================*/
.sidebar {
  min-height: 100vh;

  width: 20%;
  background-color: #410d63;
}

.header {
  width: 100%;
}

.header img {
  margin-top: 10px;
  margin-left: 20px;
  width: 75%;
}

.list-item {
  color: white;
  font-size: 16px;
  padding: 8px 20px;
  list-style: none;
}

.icon-holder {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: white;
  color: black;
}

/* .icon-holder:hover {
	color: white;
} */

.side-list {
  margin-top: 20px;
  margin-left: 5px;
}

.main-group {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.list-group {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 12px;
}

.submenu {
  margin-left: 45px;
}

.sublist {
  margin: 10px;
}

.sublist:hover {
  cursor: pointer;
}

.list-group:hover {
  cursor: pointer;
  color: whitesmoke;
}

/*======================== End Sidebar ==========================*/

.right {
  width: 100%;
}

/*============================= Searchbar ============================*/
.input {
  width: 100%;
  border: 0;
  outline: 0;
  background-color: transparent;
}

.input-group {
  margin-right: 50%;
  height: 45px;
  display: flex;

  align-items: center;
  gap: 5px;
  font-size: 20px;

  background-color: whitesmoke;
  width: 270px;
  padding: 10px 10px;
  border-radius: 10px;
}

/*============================= List ==============================*/
.chart-container {
  display: flex;
  gap: 1%;

  list-style: none;
  width: 90%;
  flex-direction: row-reverse;
}

.export {
  font-weight: bolder;
  font-size: large;
  display: flex;
  float: right;
  gap: 5%;
  width: 7%;
  height: 30px;
  margin-right: 3%;
  padding-top: 0.5%;
  margin-top: 1%;
  border: none;
  border-radius: 3px;
  background-color: transparent !important;
}

.export:hover {
  cursor: pointer;
}

.expt {
  margin-left: 15%;
  background-color: transparent !important;
}

.chart-today {
  display: flex;
  border: 0.8px solid #8713d4;
  border-radius: 5px;
  font-weight: 550;
  background-color: white;
  color: black;
}

.item {
  border-right: 0.8px solid #8713d4;
  padding: 10px 10px;
}

.item:last-child {
  border: none;
}

.item:hover {
  cursor: pointer;
}

.datetex {
  overflow: hidden;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 5px;
  height: 50px;
  width: 200px;
  font-size: 20px;

  border: 0.8px solid #8713d4;
  border-radius: 5px;

  background-color: white;
}

.datetex:hover {
  cursor: pointer;
  background-color: #8713d4;
  color: whitesmoke;
}

/* .chart-date {
  height: 20px;
} */

/*================================ card-list ============================*/

.card {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.card_info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* background-image: url("./assets/AllUserImages/11.png");
	background-size: 100%; */

  width: 320px;
  height: 150px;
  color: whitesmoke;
  padding: 10px 15px;
  border-radius: 10px;
}

.card_title {
  margin-top: 15px;
  margin-left: 10px;
}

.card_subtitle {
  margin-left: 10px;
}

.card_category {
  font-size: 19px;
  display: flex;
  width: 100%;
  justify-content: flex-end;
  font-weight: 500;
}

.card-1 {
  background-image: url("./assets/AllUserImages/11.png");
  background-size: 100%;
}

.card-1:hover {
  cursor: pointer;
  transform: translateY(-10px);
  transition: all 0.5s ease-in-out;
}

.card-2 {
  background-image: url("./assets/AllUserImages/12.png");
  background-size: 100%;
}

.card-2:hover {
  cursor: pointer;
  transform: translateY(-10px);
  transition: all 0.5s ease-in-out;
}

.card-3 {
  background-image: url("./assets/AllUserImages/13.png");
  background-size: 100%;
}

.card-3:hover {
  cursor: pointer;
  transform: translateY(-10px);
  transition: all 0.5s ease-in-out;
}

.card-4 {
  background-image: url("./assets/AllUserImages/14.png");
  background-size: 100%;
}

.card-4:hover {
  cursor: pointer;
  transform: translateY(-10px);
  transition: all 0.5s ease-in-out;
}

.card-7:hover {
  cursor: pointer;
  transform: translateY(-10px);
  transition: all 0.5s ease-in-out;
}

.card-8:hover {
  cursor: pointer;
  transform: translateY(-10px);
  transition: all 0.5s ease-in-out;
}

.card-5 {
  background-image: url("./assets/AllUserImages/15.png");
  background-size: 100%;
}

.card-6 {
  background-image: url("./assets/AllUserImages/16.png");
  background-size: 100%;
}

.card-7 {
  background-image: url("./assets/AllUserImages/17.png");
  background-size: 100%;
}

.card-8 {
  background-image: url("./assets/AllUserImages/18.png");
  background-size: 100%;
}

/* .card-9 {
	background-image: url("./assets/AllUserImages/18.png");
	background-size: 100%;
} */
.card-10 {
  background-image: url("./assets/AllUserImages/1.png");
  background-size: 100%;
}

.card-11 {
  background-image: url("./assets/AllUserImages/2.png");
  background-size: 100%;
}

.card-12 {
  background-image: url("./assets/AllUserImages/3.png");
  background-size: 100%;
}

.small-size {
  margin-top: 5px;
  width: 200px;
  font-size: 10px;
  height: 100px;
}

/*============================ table or details ==========================*/

.details {
  padding: 0 1vw;
  background-color: white;
  margin-top: -40px;
  min-height: 75vh;
  margin-left: 20px;
  margin-right: 20px;
}

.content-table {
  border-bottom: none;
  border-collapse: collapse;
  margin-top: 15px;
  font-size: 0.8em;
  width: 100%;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  /* overflow-y: scroll; */
}

.content-table thead tr {
  background-color: #8713d4;
  color: white;
}

.payment {
  margin-top: 15px !important;
  margin-bottom: 0px !important;
}

.payment th {
  padding: 2px 8px !important;
}

.payment td {
  padding: 4px 8px !important;
  font-size: 13px !important;
}

.content-table th {
  padding: 10px 8px;
  position: sticky;
  top: 0;
  font-size: 13px;
  font-weight: 5 00;
  text-align: center;
  border-left: 1px solid white;
}

.content-table td {
  padding: 5px 0px;
  text-align: center;
}

.content-table tr:nth-child(even) {
  background-color: #cccccc !important;
}

.details tr:hover {
  cursor: pointer;
}

.table-container {
  margin-top: 40px;
  height: 68vh;
  width: 100%;
  overflow: auto;
  /* margin-right: 5% !important; */
}

.body-scroll {
  overflow-y: scroll;
}

/*=========================== Chart ============================= */

.back {
  background-color: whitesmoke;
  margin: 20px 20px;
}

.Dash {
  margin-left: 50px;
  margin-top: -39px;
  margin-bottom: 57px;
  color: #8713d4;
}

.chart-heading {
  margin-top: 50px;
  margin-left: 60px;
  margin-bottom: 10px;
}

/*============================ modal or popup msg ============================= */

.modal-container {
  position: absolute;
  left: 35%;
  top: 40%;

  width: 30%;
  height: 35%;
  background-color: white;
  padding: 20px 18px;
  border-radius: 10px;

  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}

.close-Icon {
  float: right;
}

/*================================ Header-container========================= */

.header-title {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-top: 30px;
  margin-left: 20px;
  transition: all 1s ease-in-out;
}

.header-title:hover {
  cursor: pointer;
  transform: translateX(-10%);
  transition: all 0.5s ease-in-out;
}

.header-container {
  display: flex;
  justify-content: space-between;
}

.header-cards {
  display: flex;
  gap: 10px;
  margin-right: 15px;
  margin-top: 15px;
}

.Header-card_info {
  height: 70px;
  display: flex;
  justify-content: space-between;
  color: whitesmoke;
  padding: 10px 15px;
  border-radius: 10px;
}

.Header-card_title {
  margin-top: 2px;
  margin-left: 10px;
}

.Header-card_subtitle {
  margin-left: 10px;
}

.Header-card_category {
  font-size: 22px;
  margin-top: 10px;
}

/*==================== mdcheckbox or approval============================= */

.approval {
  display: flex;
  align-items: center;
  gap: 10px;
}

.facheckbox {
  font-size: 26px;

  color: #30ed2d;
}

.fawindowclose {
  font-size: 26px;
  color: red;
}

/*===========================hostreqmodal============================  */

.hostreqmodal-content {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  min-width: 500px;
  background: white;
  padding: 20px 20px;
  border-radius: 10px;
}

.hostreqmodal {
  font-size: 10px;
}

.approvebutton > button {
  margin-top: 15px;
  background-color: #30ed2d;
  padding: 10px 15px;
  border-radius: 5px;
}

.cancelbutton > button {
  margin-top: 15px;
  background-color: #f33328;
  padding: 10px 15px;
  border-radius: 5px;
}

.hostbutton-container {
  display: flex;
  justify-content: space-around;
}

.hostbutton-container :hover {
  cursor: pointer;
  color: white;
}

.hostreqclose-Icon {
  width: 100%;

  display: flex-start;
  justify-content: flex-end;
}

/* end hostreqmodal  */

/* app request */
.level3 {
  margin-left: 0px;
}

/* @media (max-width: 768px) {
	.dashboard {
		display: block;
	}
} */

/* ========================LOGIN======================= */

.loginimg {
  /* width: 100%;
	height: 100%; */

  display: flex;
}

.login {
  font-size: 50px;
  color: #410d63;
}

.headerlogin {
  display: flex;
  align-items: center;
  flex-direction: column;
}

/* .imgcontainer {
	width: 50vw;
	height: 100vh;
} */
.hero {
  width: 50vw;
  height: 100vh;
  margin-bottom: -5px;
}

.mainlogin {
  /* width: 50%; */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-right: 30px;
  gap: 40px;
}

.username {
  padding: 10px;
}

.usernamebox {
  overflow: hidden;
  border-width: 0.9px;
  border-color: #9e9e9e;
  padding: 5px;
  border-radius: 10px;
  height: 50px;
  width: 500px;
}

.secondbox {
  margin-top: 40px;

  height: 90px;
  display: flex;
  flex-direction: column;
}

.userpassword {
  padding: 10px;
}

.passbox {
  overflow: hidden;
  border-width: 0.9px;
  border-color: #9e9e9e;
  padding: 5px;
  border-radius: 10px;
  height: 50px;
  width: 500px;
}

.submitbtn {
  border: none;

  border-radius: 10px;

  color: white;
  font-size: large;
  background-color: #410d63;
  height: 60px;
  width: 500px;
}

.submitbtn:hover {
  cursor: pointer;
}

/* ===============ADD BANNER================== */

.addbannermain {
  background-color: white;
  width: 100vw;
  min-height: 550px;
  max-height: fit-content;
  width: 98%;
  margin-right: 1%;
  margin-left: 1%;
  margin-top: 2%;
  padding-top: 20px;
  border-radius: 5px;
  padding-bottom: 10px;
}

.bantext {
  color: #410d63;
  font-size: larger;
  font-weight: 500;
  margin-left: 2%;
}

.choosetext {
  height: 10%;
  margin-top: 2%;
  /* background-color: #30ed2d; */
}

.addchoosetext {
  /* background-color: #f33328; */
  margin-left: 5%;
  font-size: larger;
  font-weight: 500;
  /* margin-top: 2%; */
}

.choosefile {
  width: 45%;
  height: 45px;
  border: 1px solid rgb(20, 19, 19);
  margin-top: 5px;
  margin-left: 5%;
  padding-top: 0.5%;
  padding-left: 1%;
  border-radius: 5px;
}

.choosebtn {
  color: black;
  border: 1px solid rgb(20, 19, 19);
  width: 150px;
  height: 47px;
  border-radius: 8px;
  font-family: sans-serif;
}

.choosebtn:hover {
  cursor: pointer;
}

.filebtn:hover {
  background-color: #30ed2d;
  cursor: pointer;
}

.briefdescription {
  font-size: larger;
  font-weight: 500;
  width: 45%;
  height: 20%;
  margin-left: 5%;
  display: flex;
  flex-direction: column;
}

.brieftext {
  margin-top: 2%;
  height: 100%;
  width: 100%;
  border-radius: 5px;
  overflow: none;
  border: 1px solid rgb(20, 19, 19);
  padding-left: 5px;
  padding-bottom: 70px;
  font-size: 17px;
}

.addinput {
  font-size: larger;
  font-weight: 500;
  width: 90%;
  margin-left: 5%;
  display: flex;
  flex-direction: column;
}

.addinput input {
  height: 50px !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
  display: none;
}

.addinputtext {
  margin-top: 5px !important;
  height: 45px;
  width: 100%;
  border-radius: 5px;
  overflow: none;
  border: 1px solid rgb(20, 19, 19);
  padding-left: 5px;
  font-size: 17px;
  margin-bottom: 20px !important;
}

.savecancelbutton {
  width: 40%;
  height: 5%;
  margin-left: 4%;
}

.savecancelbuttonWalet {
  width: 40%;
  height: 5%;
  margin-left: 5%;
  margin-top: 50px;
}

.submitbutton {
  background-color: #410d63;
  font-size: 20px;
  color: white;
  width: 150px;
  height: 47px;
  border-radius: 8px;
  border: none;
  margin-top: -50px !important;
}

.submitbutton:hover {
  cursor: pointer;
}

.canbutton {
  width: 20%;
  height: 100%;
  margin-left: 10%;
  background-color: white;
  border: 2px solid #410d63;
  border-radius: 5px;
  color: #410d63;
  font-size: medium;
  font-weight: 500;
}

.cancelbutton:hover {
  cursor: pointer;
}

.updateImageBtn button {
  background-color: #410d63;
  font-size: 20px;
  color: white;
  width: 150px;
  height: 47px;
  border-radius: 8px;
  border: none;
}

.updateImageBtn {
  display: flex;
  justify-content: center;
}

/* =================lIST OF BANNER Table=============== */

.Listbannermain {
  background-color: white;
  width: 100vw;
  height: 100vh;
  width: 98%;
  height: 40%;
  margin-right: 1%;
  margin-left: 1%;
  margin-top: 4%;
  margin-bottom: 10%;
  border-radius: 5px;
}

.listtable-container {
  margin-top: 40px;

  width: 100%;
}

.listcontent-table {
  border-collapse: collapse;

  border-radius: 10px 10px;
  overflow: hidden;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 100%;
}

.listcontent-table th {
  background-color: #ad42f5;

  font-size: large;
  position: sticky;
  top: 0;

  border-collapse: collapse;

  overflow: hidden;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}

/* =======================Addsubadminbtn============== */
.Addsubadminbtn {
  background-color: white;

  width: 100vw;
  height: 100vh;
  width: 98%;
  margin-right: 1%;
  margin-left: 1%;
  margin-top: 4%;
  margin-bottom: 10%;
  border-radius: 5px;
}

.addsubadmintext {
  color: #410d63;
  font-size: larger;
  font-weight: 500;
  margin-left: 2%;
}

.addsubadminusername {
  display: flex;
  width: 80vw;
  /* height: 20vh; */
  width: 80%;
  gap: 15%;
}

.addinputtextsubadmin {
  background-color: white;
  height: 5vh;
  border-radius: 5px;
  overflow: hidden;
  border: none;
  margin-top: 2%;
  border: 1px solid rgb(20, 20, 20);
  padding-left: 5px;
  font-size: medium;
}

.ResponsibilitiesGiven {
  /* background-color: #f33328; */
  margin-top: 4%;
  padding-left: 2%;
  font-size: medium;
  color: #410d63;
  font-weight: 500;
}

.checkboxresgivenmain {
  margin-top: 2%;
}

.checkboxresgiven {
  margin-top: 2%;

  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.oneUserImg {
  width: 200px !important;
  height: 200px;
  border-radius: 100px;
  box-shadow: 1px 1px 5px rgb(179, 179, 179);
  border: none;
  border-color: #ffffff;
}

.oneUserImg2 {
  width: 200px !important;
  height: 200px;
  border-radius: 100px;
  box-shadow: 1px 1px 5px rgb(210, 210, 210);
  margin-left: 1px !important;
}

.oneUserImg22 {
  width: 230px !important;
  height: auto;
  box-shadow: 1px 1px 5px rgb(210, 210, 210);
}

.imgBox {
  width: 20%;
}

.imgBox2 {
  width: 30%;
  margin-left: -20px;
}

.imgBox2 p {
  text-align: center;
  font-size: 18px;
}

.imgBox p {
  text-align: center;
  font-size: 18px;
}

.oneflex {
  display: flex;
  justify-content: space-between;
}

.oneflex video {
  border: 1px solid black;
  margin-left: 15px;
  margin-top: 10px;
}

.tableBox {
  width: 70%;
  display: flex;
  /* background-color: #ad42f5; */
}

.oneUserTable {
  width: 50%;
  border: 0px;
}

.oneUserTable th {
  width: 30%;
  text-align: left;
  padding: 4px 2px;
  border: none !important;
  font-size: 14px;
}

.oneUserTable td {
  text-align: left;
  padding: 4px 2px;
  border: none !important;
  font-size: 14px;
}

.paymentHead {
  color: #8713d4;
  font-size: 20px;
  margin: 0px -15px -10px 10px;
  font-weight: 500;
}

.ARbtns {
  display: flex;
  justify-content: center;
}

.accptBtn {
  background-color: #410d63;
  font-size: 20px;
  color: white;
  width: 150px;
  height: 47px;
  border-radius: 8px;
  border: none;
  margin: 20px;
  cursor: pointer;
}

.rejectBtn {
  background-color: transparent;
  font-size: 20px;
  color: #410d63;
  width: 150px;
  height: 47px;
  border-radius: 8px;
  border: 2px solid #410d63;
  margin: 20px;
  cursor: pointer;
}

.AddCharge {
  background-color: #8713d4;
  font-size: 15px;
  color: #ffffff;
  width: 60px;
  height: 25px;
  cursor: pointer;
  border-radius: 2px;
  border: none;
}

.ListEdit {
  color: #06a703;
  font-size: 20px;
  padding: 0px 10px 0px 0px;
}

.HostEdit {
  color: #8713d4;
  font-size: 18px;
  padding: 0px 10px 0px 0px;
}

.ListDelte {
  color: #ff0303;
  font-size: 18px;
}

.colseModal {
  position: fixed;
  top: 0px;
  right: 0px;
  background-color: #ff0303;
  color: #ffffff;
  border: none;
  font-size: 20px;
  font-weight: 600;
  padding: 5px 10px;
  cursor: pointer;
}

.updateModal input {
  width: 100%;
  height: 40px;
  margin: 0px 0px 20px 0px;
}

.updateModal label {
  font-size: 18px;
  padding-bottom: 5px;
  font-weight: 500;
}

.UpdateBtn {
  background-color: #410d63;
  font-size: 20px;
  color: #ffffff;
  width: 150px;
  height: 47px;
  border-radius: 8px;
  border: 2px solid #410d63;
  cursor: pointer;
}

.CancelBtn {
  background-color: transparent;
  font-size: 20px;
  color: #410d63;
  width: 150px;
  height: 47px;
  border-radius: 8px;
  border: 2px solid #410d63;
  cursor: pointer;
}

.updateBtns {
  display: flex;
  justify-content: space-around;
  margin-top: 40px;
}

.addHostCharge {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.addHostCharge input {
  width: 60%;
  height: 40px;
}

.addHostCharge button {
  background-color: #410d63;
  font-size: 20px;
  color: #ffffff;
  width: 150px;
  height: 35px;
  border: 2px solid #410d63;
  cursor: pointer;
  margin-top: 30px;
}

.bannerImage {
  width: 80%;
  max-height: 90vh;
  margin-left: 10%;
}

.textBox {
  min-height: 80px;
  padding: 5px;
  font-weight: 500;
  font-size: 17px;
  border-radius: 5px;
  margin-top: 5px;
}

.form-label {
  font-weight: bold;
}

.form-control {
  width: 100%;
  padding: 8px;
  margin: 6px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.form-button {
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.form-button:hover {
  background-color: #0056b3;
}

.smallInput {
  width: 50% !important;
}

.paginationAlign {
  display: flex;
  justify-content: end;
  margin-right: 50px;
}

.spinnerBg {
  position: fixed;
  top: 70px;
  background-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0.5),
    rgb(0, 0, 0, 0.5)
  );
  height: 100vh !important;
  width: 100%;
}

.spinnerBg div {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  width: 100%;
}

.spin {
  margin-left: -20% !important;
}

.closeModal {
  position: absolute;
  top: 0px;
  right: 0px;
  background-color: #ff1a0e;
  color: #ffffff;
  border: none;
  padding: 5px 10px;
  font-size: 20px;
  font-weight: 600;
  cursor: pointer;
}

.alignInBtn {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.alignInBtn button {
  background-color: #8713d4;
  font-size: 20px;
  color: #ffffff;
  border: none;
  width: 150px;
  height: 40px;
  border-radius: 5px;
  cursor: pointer;
  margin: 20px 50px 0px 50px;
}

.helpLineInput {
  border: 1px solid black;
  width: 80%;
  height: 50px;
  font-size: 16px;
  padding-left: 5px;
}

/* notification  */
.notificationHead {
  font-size: 22px;
  font-weight: 500;
}

.noticationBox {
  padding: 0px 20px;
}

.NotificationSelect {
  width: 90%;
  height: 40px;
  margin: 10px 0px;
  font-size: 15px;
}

.sendBTnNoti {
  display: block;
  width: 120px;
  background-color: #8713d4;
  height: 35px;
  color: #ffffff;
  border: none;
  font-weight: 500;
  font-size: 18px;
  border-radius: 5px;
  margin-bottom: 20px;
  cursor: pointer;
}

.notiInput {
  display: block;
  width: 90%;
  height: 40px;
  padding: 5px;
  font-size: 15px;
  color: black;
  margin-bottom: 10px;
}

.notiInput2 {
  display: inline-block;
  width: 76%;
  height: 40px;
  padding: 5px;
  font-size: 15px;
  color: black;
  margin-bottom: 10px;
}

.notiInput22 {
  display: inline-block;
  width: 90%;
  height: 40px;
  padding: 5px;
  font-size: 15px;
  color: rgb(96, 96, 96);
  margin-bottom: 10px;
  background-color: #ffffff;
  border: 1px solid rgb(98, 98, 98);
  margin-top: 5px;
  text-align: left;
  cursor: pointer;
}

.notiInput3 {
  display: inline-block;
  width: 81%;
  height: 40px;
  padding: 5px;
  font-size: 15px;
  color: black;
  margin-bottom: 10px;
}

.sendBTnNotiAdd {
  position: relative;
  top: 1px;
  display: inline;
  width: 15%;
  background-color: #39ac67;
  height: 35px;
  color: #ffffff;
  border: none;
  font-weight: 500;
  font-size: 15px;
  margin-left: -1%;
  cursor: pointer;
  border: 1px solid #666666;
  border-radius: 2px;
  border: none;
  margin-right: 50px;
  margin-bottom: 20px;
}

.sendBTnNotiAdd3 {
  position: relative;
  top: 1px;
  display: inline;
  width: 20%;
  background-color: #39ac67;
  height: 40px;
  color: #ffffff;
  border: none;
  font-weight: 500;
  font-size: 18px;
  margin-left: -1%;
  cursor: pointer;
  border: 1px solid #666666;
  border-radius: 5px;
  margin-left: 40%;
}

.sendBTnNotiAdd2 {
  position: relative;
  top: 0.5px;
  display: inline;
  width: 10%;
  background-color: #dd3232;
  height: 40px;
  color: #ffffff;
  border: none;
  font-weight: 500;
  font-size: 18px;
  margin-left: -1%;
  cursor: pointer;
  border: 1px solid #666666;
}

.SingleName {
  position: relative;
  left: 42%;
  top: -40px;
  background-color: #d5d5d5;
  font-size: 14px;
  padding: 3px 5px;
  border-radius: 5px;
}

.SingleName span {
  font-weight: 700;
  cursor: pointer;
  padding: 0px 5px;
  display: none;
}

.noitficationDisplay {
  display: flex;
}

.leftBox {
  width: 50%;
}

.rightBox {
  width: 50%;
}

.namebyId {
  color: #000000;
  font-weight: 500;
}

.ModalHead {
  font-size: 25px;
  font-weight: 500;
  text-align: center;
}

.closeBtnModal {
  position: absolute;
  background-color: #dd3232;
  color: #ffffff;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 25px;
  font-weight: 500;
  top: 0px;
  right: 0px;
  cursor: pointer;
}

.modalInput {
  width: 70%;
  height: 40px;
  margin: 20px 15%;
  padding: 5px;
  font-size: 17px;
}

.moreBtns {
  display: flex;
  width: 90%;
  margin-left: 5px;
}

.tableRemove {
  width: 90%;
  margin: auto;
  margin-top: 20px;
}

.tableRemove tr td:nth-child(even) {
  text-align: center;
  background-color: #dd3232;
  width: 80px;
  color: #ffffff;
  cursor: pointer;
}

.tableRemove tr td:nth-child(odd) {
  text-align: center;
  background-color: #e5e4e4;
  margin-top: 10px !important;
  padding: 8px;
}

.ActionButtons {
  display: flex;
  justify-content: space-around;
  margin: 30px 0px 10px 0px;
}

.ActionButtons button {
  background-color: #8713d4;
  border: none;
  width: 135px;
  height: 35px;
  border-radius: 5px;
  font-size: 15px;
  font-weight: 500;
  color: #ffffff;
  cursor: pointer;
}

.SearchInput {
  position: relative;
  left: 65%;
  height: 35px;
  width: 250px;
  margin-top: 10px;
  padding: 5px;
  font-size: 15px;
}

.host_list_table {
  width: 100%;
  margin-top: -30px;
}

.host_list_table tr th {
  text-align: start;
}

.container {
  margin-right: 30px;
}

@media screen and (min-width: 1200px) {
  body {
    overflow-x: hidden;
  }
}

/* Styling for the entire table */
.cardTable {
  width: 100%;
  border-collapse: collapse;
  font-family: Arial, sans-serif;
  border: 1px solid #ddd;
  background-color: #fff;
}

/* Styling for table header row */
.cardTableHeader {
  background-color: #f1f4f7;
  color: gray;
  font-weight: bold;
}

/* Styling for table rows */
.cardTableRow:nth-child(even) {
  background-color: #f2f2f2;
}

.cardTableRow:nth-child(odd) {
  background-color: #fff;
}

/* Styling for table cells (td) */
.cardTableRow td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
}

/* Add additional styling as needed */
