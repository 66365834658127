.passwordInput {
  position: relative;
}

.passwordEye {
  position: absolute;
  top: 25%;
  right: 2%;
  cursor: pointer;
}
